import { useNavigate } from 'react-router-dom';
import { useApi } from 'services/api';
import Header from 'components/Header';

export default function Home() {
  const navigate = useNavigate();
  const api = useApi();

  const handle400 = async () => {
    try {
      const response = await api.get(`/test-error-400`);
      // console.log(response);
    } catch (e) {
      console.error('400:', e);
    }
  };

  const handle401 = async () => {
    try {
      const response = await api.get(`/test-error-401`);
      console.log(response);
    } catch (e) {
      console.error(e);
    }
  };

  const handle403 = async () => {
    try {
      const response = await api.get(`/test-error-403`);
      console.log(response);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className='flex flex-col w-full h-full'>
      {/* 헤더 */}
      <Header />
      <div className='flex flex-col w-full items-center justify-center h-full'>
        <div className='flex flex-col md:flex-row w-full items-center justify-between gap-x-4'>
          <div className='flex flex-col text-2xl font-bold w-full'>
            <h4>만나서 반가워!</h4>
            <h4>오늘은 무엇을 해볼까?</h4>
          </div>
          <div className='flex w-full'>
            <img
              src={require('assets/images/home/section_1.svg').default}
              alt='section-1'
              className='w-full'
            />
          </div>
        </div>
        <div className='flex flex-row w-full items-center gap-x-4 text-2xl md:text-4xl font-semibold'>
          <button
            onClick={() => {
              navigate('/learn');
            }}
            className='relative flex w-full justify-start'
          >
            <div className='absolute top-5 md:top-10 left-1/2 transform -translate-x-1/2'>
              <h3>영어학습</h3>
            </div>
            <img
              src={require('assets/images/home/section_2.svg').default}
              alt='section-2'
            />
          </button>
          <button
            onClick={() => {
              navigate('/learn/dialog');
            }}
            className='relative flex w-full justify-end'
          >
            <div className='absolute top-5 md:top-10 left-1/2 transform -translate-x-1/2'>
              <h3 className='text-white'>심리상담</h3>
            </div>
            <img
              src={require('assets/images/home/section_3.svg').default}
              alt='section-3'
            />
          </button>
        </div>
        {/* <button onClick={handle400}>400에러 리턴</button>
        <button onClick={handle401}>401에러 리턴</button>
        <button onClick={handle403}>403에러 리턴</button> */}
      </div>
    </div>
  );
}
