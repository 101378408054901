import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://65442a927c17a92d3ef6feeb418d9a09@o4508220666019840.ingest.us.sentry.io/4508220668837888",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    // Array of methods that should be captured. Defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
    Sentry.captureConsoleIntegration(["warn", "error"])
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // https://test-camera-mike.pages.dev/
  // https://dev.aisa.co.kr/
  tracePropagationTargets: [/^https:\/\/dev\.aisa\.co\.kr/, /^https:\/\/aisa\.co\.kr/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
