import { create } from 'zustand';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

dayjs.locale('ko'); // global로 한국어 locale 사용

const useStore = create((set) => ({
  count: 0,
  increase: () => set((state) => ({ count: state.count + 1 })),
  decrease: () => set((state) => ({ count: state.count - 1 })),
}));

/** 실행 환경 구분: 브라우저 / 로봇 안드로이드 웹뷰 */
const useEnvironmentStore = create((set, get) => ({
  env: localStorage.getItem("env") || 'browser',
  setEnvironment: (env) => {
    localStorage.setItem("env", env);
    set({ env: env });
  },
  isBrowser: () => get().env === 'browser',
  isRobotAndroidWebview: () => get().env === 'robot-android-webview',
}));


const usePopupStore = create((set) => ({
  character: require('../src/assets/icons/common/character.svg').default,
  popup: 0,

  errorMessage: '',
  errorCancel: require('../src/assets/icons/common/error-cancel.svg').default,
  showError: false,
  setError: (message) =>
    set({ errorMessage: message, showError: true, popup: 0 }),
  clearError: () => set({ errorMessage: '', showError: false }),

  checkMessage: '',
  checkCancel: require('../src/assets/icons/common/error-cancel.svg').default,
  showCheck: false,

  setCheck: (message) =>
    set({ checkMessage: message, showCheck: true, popup: 1 }),
  clearCheck: () => set({ checkMessage: '', showCheck: false }),
}));

const useAuthStore = create((set, get) => ({
  // 사용자 정보
  userData: {
    schoolName: '',
    studentGradeLevel: '',
    studentClassName: '',
    studentNumber: '',
    displayName: '',
  },

  setUserData: (data) => set({ userData: data }),
  getUserData: () => get().userData,

  // 액세스 토큰
  accessToken: null,
  tokenExpiryTime: null,

  setAccessToken: (token) => {
    // const today = dayjs();
    // const expiryTime = today.add(30, 'minute').valueOf();
    set({ accessToken: token });
  },

  clearAccessToken: () => set({ accessToken: null, tokenExpiryTime: null }),

  // 로그아웃 함수 추가
  clearAuth: () =>
    set({
      userData: {
        schoolName: '',
        studentGradeLevel: '',
        studentClassName: '',
        studentNumber: '',
        displayName: '',
      },
      accessToken: null,
      tokenExpiryTime: null,
    }),
}));

const selectLearnStore = create((set) => ({
  learnType: '',
  learnLevel: null,
  learnTopicLevel: null,

  setLearnType: (data) => set({ learnType: data }),
  setLearnLevel: (data) => set({ learnLevel: data }),
  setLearnTopicLevel: (data) => set({ learnTopicLevel: data }),
}));

export { useStore, useEnvironmentStore, usePopupStore, useAuthStore, selectLearnStore };
