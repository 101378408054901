import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import PermissionPopup from './PermissionPopup';

const DefaultLayout = () => {
  const [isPermissionGranted, setIsPermissionGranted] = useState(false);
  const [isPermissionDenied, setIsPermissionDenied] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const permissionStatus = localStorage.getItem('permissionGranted');
    if (permissionStatus === 'true') {
      setIsPermissionGranted(true);
    } else {
      setShowPopup(true);
    }
  }, []);

  // 권한 허용 시
  const handlePermissionAllow = () => {
    setIsPermissionGranted(true);
    setShowPopup(false);
    localStorage.setItem('permissionGranted', 'true');
  };

  // 권한 거부 시
  const handlePermissionDeny = () => {
    setIsPermissionDenied(true);
    setShowPopup(false);
    localStorage.setItem('permissionGranted', 'false');
  };

  // 팝업을 띄우는 함수
  const showPermissionPopup = () => {
    setShowPopup(true);
  };

  return (
    <div className='flex flex-col p-6 px-8 max-w-[1024px] min-w-[601px] max-h-[768px] min-h-[768px] items-center justify-center w-screen h-svh'>
      {/* 권한 허용 팝업 */}
      {!isPermissionGranted && showPopup && (
        <PermissionPopup
          onAllow={handlePermissionAllow}
          onDeny={handlePermissionDeny}
        />
      )}

      {/* 권한 허용 시 */}
      {isPermissionGranted ? (
        <Outlet />
      ) : !showPopup ? (
        <div className='flex flex-col items-center gap-y-10'>
          <p>권한을 허용하지 않으면 서비스를 사용할 수 없습니다.</p>
          <button
            onClick={showPermissionPopup}
            className='flex w-full py-[10px] rounded-xl bg-gradient-to-b from-[#B8DE55] to-[#91CA00] text-white items-center justify-center'
          >
            권한 허용하기
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default DefaultLayout;
