import React, { useState } from 'react';

import * as Sentry from '@sentry/react';

export default function PermissionPopup({ onAllow, onDeny }) {
  const [isPopupVisible, setIsPopupVisible] = useState(true);

  const handleAllow = async () => {
    try {
      // 앱 최초 실행 시 비디오, 마이크 권한 요청
      const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

      // TODO: 연동 끝나고 삭제 예정
      console.warn('mediaStream.getTracks()', mediaStream.getTracks());// 모든 트랙 조회
      mediaStream.getTracks().forEach((track) => {
        const mediaStreamTrackJson = JSON.stringify({
          kind: track.kind,
          id: track.id,
          label: track.label,
          enabled: track.enabled,
          muted: track.muted,
          readyState: track.readyState,
        }, null, 2);
        Sentry.captureMessage(mediaStreamTrackJson);
      });

      // TODO: 연동 끝나고 삭제 예정
      // 권한이 허용된 디바이스 조회
      const devices = await navigator.mediaDevices.enumerateDevices();
      devices.forEach(device => {
        Sentry.captureMessage(`enumerateDevices - Device kind: ${device.kind}, label: ${device.label}, id: ${device.deviceId}`);
        console.log(`enumerateDevices - Device kind: ${device.kind}, label: ${device.label}, id: ${device.deviceId}`);
      });

      onAllow();
      setIsPopupVisible(false);
    } catch (error) {
      console.error('권한 요청 실패:', error);
      Sentry.captureException(error);
      alert('비디오 및 마이크 권한을 허용해야 합니다.');
    }
  };

  const handleDeny = () => {
    onDeny();
    setIsPopupVisible(false);
  };

  if (!isPopupVisible) {
    return null;
  }

  return (
    <div className='fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center'>
      <div className='bg-[#ffffffc4] p-6 rounded-2xl shadow-lg'>
        <p className='mb-4 py-10 px-4'>
          앱을 사용하려면 비디오 및 마이크 권한이 필요합니다.
        </p>
        <div className='flex flex-row justify-center gap-x-10'>
          <button
            onClick={handleAllow}
            className='flex w-full py-[10px] rounded-xl bg-gradient-to-b from-[#B8DE55] to-[#91CA00] text-white items-center justify-center'
          >
            권한 허용
          </button>
          <button
            onClick={handleDeny} // 허용 안함 처리 함수 호출
            className='flex w-full py-[10px] rounded-xl bg-white items-center justify-center'
          >
            허용 안함
          </button>
        </div>
      </div>
    </div>
  );
}
