import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthStore } from 'store';
import { useApi } from 'services/api';
import { usePopupStore } from 'store';
import axios from 'axios';

export default function AuthLayout() {
  const { accessToken, setAccessToken, setUserData } = useAuthStore();
  const [loading, setLoading] = useState(true);
  const setError = usePopupStore((state) => state.setError);
  const api = useApi();

  useEffect(() => {
    const checkRefreshToken = async () => {
      try {
        const response = await api.get('/v1/auth/refresh-token-check');
        return response.data.result === 'SUCCESS';
      } catch (error) {
        handleLogout();
        return false;
      }
    };

    const fetchUserData = async () => {
      try {
        if (accessToken) {
          const response = await api.get('/v1/member/info');
          if (response.data.result === 'SUCCESS') {
            setUserData(response.data.data);
          } else {
            throw new Error('유저 정보 가져오기 실패');
          }
        }
      } catch (error) {
        setError('사용자 정보를 가져오는 중 오류가 발생했습니다.');
      }
    };

    const checkAccessToken = async () => {
      try {
        if (!accessToken) {
          const isValid = await checkRefreshToken();

          if (!isValid) {
            return;
          }

          const refreshResponse = await api.post(
            `/v1/auth/access-token-reissue`
          );

          if (refreshResponse.data.result === 'SUCCESS') {
            setAccessToken(refreshResponse.data.data.accessToken); // 재발급된 토큰 저장
            await fetchUserData(); // 유저 정보 가져오기
          }
        } else {
          await fetchUserData(); // 이미 있는 토큰으로 유저 정보 요청
        }
      } catch (error) {
        handleLogout();
      } finally {
        setLoading(false);
      }
    };

    const handleLogout = async () => {
      try {
        await api.post('/v1/auth/logout');
      } catch (e) {
        console.log(e);
      }
    };

    checkAccessToken();
  }, [accessToken]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return <Outlet />;
}
