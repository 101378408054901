import { useNavigate } from 'react-router-dom';
import { selectLearnStore, useAuthStore } from 'store';
import { ReactComponent as Number1 } from 'assets/images/learnEng/number_1.svg';
import { ReactComponent as Number2 } from 'assets/images/learnEng/number_2.svg';
import { ReactComponent as Number3 } from 'assets/images/learnEng/number_3.svg';
import { ReactComponent as Number4 } from 'assets/images/learnEng/number_4.svg';
import { ReactComponent as Number5 } from 'assets/images/learnEng/number_5.svg';
import { ReactComponent as Number6 } from 'assets/images/learnEng/number_6.svg';
import { useEffect, useState } from 'react';
import Header from 'components/Header';
import { usePopupStore } from 'store';

export default function Learn() {
  const navigate = useNavigate();
  const { setError } = usePopupStore();
  const { userData, setUserData } = useAuthStore();
  const { learnType, setLearnType, setLearnLevel, learnLevel } =
    selectLearnStore();

  const activeColor = '#808080'; // 선택된 버튼의 색상 1

  const handleNumberClick = (level) => {
    setLearnLevel(level);
  };

  useEffect(() => {
    setLearnType('');
  }, []);

  // 버튼 스타일을 동적으로 설정하는 함수
  const getButtonStyle = (level) => {
    return learnLevel === level
      ? {
          '--stop-color1': activeColor,
          '--stop-color2': activeColor,
          '--stop-color3': activeColor,
        }
      : {};
  };

  return (
    <div className='flex flex-col w-full h-full items-center'>
      <div className='flex flex-col w-full h-full'>
        {/* 헤더 */}
        <Header />
        <div className='flex flex-col w-full items-center justify-center h-full'>
          {/* <div className='mx-auto my-auto'> */}
          <div className='flex flex-col w-full items-center justify-between gap-y-4'>
            <div className='flex flex-1 w-full flex-col text-2xl bg-white bg-opacity-60 rounded-lg justify-center items-center p-8 shadow-[0_0_12px_#0000001A]'>
              <span className='h-[100px]'></span>
            </div>
            <div className='flex flex-1 w-full flex-col text-2xl bg-white font-semibold bg-opacity-60 rounded-lg h-[120px] justify-center p-8 shadow-[0_0_12px_#0000001A] gap-y-4'>
              <div className='flex flex-row items-center gap-x-4'>
                <h3>학습 설정</h3>
                <span className='text-lg'>
                  언제든 다른 학년 학습을 예습 복습 할 수 있어요
                </span>
              </div>
              <div className='flex flex-row items-center w-full justify-between gap-x-4'>
                <h3 className='text-lg'>학년</h3>

                {/* SVG 버튼들 */}
                <button
                  onClick={() => handleNumberClick(1)}
                  style={getButtonStyle(1)}
                >
                  <Number1 />
                </button>
                <button
                  onClick={() => handleNumberClick(2)}
                  style={getButtonStyle(2)}
                >
                  <Number2 />
                </button>
                <button
                  onClick={() => handleNumberClick(3)}
                  style={getButtonStyle(3)}
                >
                  <Number3 />
                </button>
                <button
                  onClick={() => handleNumberClick(4)}
                  style={getButtonStyle(4)}
                >
                  <Number4 />
                </button>
                <button
                  onClick={() => handleNumberClick(5)}
                  style={getButtonStyle(5)}
                >
                  <Number5 />
                </button>
                <button
                  onClick={() => handleNumberClick(6)}
                  style={getButtonStyle(6)}
                >
                  <Number6 />
                </button>
              </div>
            </div>
          </div>

          <div className='grid grid-cols-2 w-full items-center gap-5 my-10 place-items-center'>
            <button
              onClick={() => {
                if (learnLevel !== null) {
                  setLearnType('letter');
                  navigate('/learn/topic');
                } else {
                  setError('학년을 먼저 선택해주세요!');
                }
              }}
              className='relative flex flex-1 justify-start'
            >
              <img
                src={require('assets/images/learnEng/letter.svg').default}
                alt='section-2'
              />
            </button>
            <button
              onClick={() => {
                if (learnLevel !== null) {
                  setLearnType('word');
                  navigate('/learn/topic');
                } else {
                  setError('학년을 먼저 선택해주세요!');
                }
              }}
              className='relative flex flex-1 justify-start'
            >
              <img
                src={require('assets/images/learnEng/word.svg').default}
                alt='section-2'
              />
            </button>
            <button
              onClick={() => {
                if (learnLevel !== null) {
                  setLearnType('sentence');
                  navigate('/learn/topic');
                } else {
                  setError('학년을 먼저 선택해주세요!');
                }
              }}
              className='relative flex flex-1 justify-start'
            >
              <img
                src={require('assets/images/learnEng/sentence.svg').default}
                alt='section-2'
              />
            </button>
            <button
              onClick={() => {
                if (learnLevel !== null) {
                  setLearnType('guess');
                  navigate('/learn/topic');
                } else {
                  setError('학년을 먼저 선택해주세요!');
                }
              }}
              className='relative flex flex-1 justify-start'
            >
              <img
                src={require('assets/images/learnEng/guess.svg').default}
                alt='section-2'
              />
            </button>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}
