import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store';

export default function Dialog() {
  const navigate = useNavigate();
  const { userData, setUserData } = useAuthStore();

  return (
    <div className='flex flex-col w-full h-full items-center'>
      <div className='flex flex-col w-full h-full'>
        {/* 헤더 */}
        <div className='flex flex-row justify-between items-center py-6'>
          <div
            onClick={() => {
              navigate('/');
            }}
            className='flex flex-row items-center gap-x-5'
          >
            <img
              src={require('../../assets/icons/common/back-btn.svg').default}
              width={44}
              height={46}
              alt='back button'
            />
            <h3 className='text-2xl text-[#990000]'>Level 1</h3>
          </div>
          <div className='flex flex-row items-center gap-x-8'>
            <div className='flex flex-row items-center gap-x-2'>
              <img
                src={
                  require('../../assets/icons/common/user-level.svg').default
                }
                width={28}
                height={28}
                alt='level icon'
              />
              {/* TODO: 상태에서 가져온 사용자의 정보 표시 */}
              <h3 className='text-[#990000] font-extrabold'>
                {userData?.displayName === ''
                  ? `${userData.schoolName} ${userData.studentGradeLevel}학년 ${userData.studentClassName}반 ${userData.studentNumber}번 `
                  : userData.displayName}
                님
              </h3>
            </div>
            <div
              className='flex flex-row items-center gap-x-2 border-[3px] border-[#FFFFFF] py-[5px] px-3 rounded-xl'
              onClick={() => {
                navigate('/login');
              }}
            >
              <img
                src={
                  require('../../assets/icons/common/logout-time.svg').default
                }
                width={28}
                height={28}
                alt='level icon'
                className='bg-cover'
              />
              <h3 className='text-[#990000] font-extrabold'>Logout</h3>
            </div>
          </div>
        </div>
        <div className='flex flex-col w-full items-center justify-center h-full'>
          <div className='flex flex-row w-full justify-between'>
            <div className='flex flex-col gap-y-4'>
              <img
                src={
                  require('../../assets/images/dialog/dialog-character.svg')
                    .default
                }
                alt='section-2'
              />
              <button>
                <img
                  src={
                    require('../../assets/images/dialog/story-btn.svg').default
                  }
                  alt='section-2'
                />
              </button>
              <button>
                <img
                  src={
                    require('../../assets/images/dialog/change-avatar-btn.svg')
                      .default
                  }
                  alt='section-2'
                />
              </button>
            </div>
            <div className='flex flex-col'>
              <div className='flex w-[728px]'>
                <img
                  src={
                    require('../../assets/images/dialog/title-bg.svg').default
                  }
                  alt='section-2'
                />
              </div>
              <div className='flex w-[724px] h-full shadow-[0_0_12px_#0000001A] p-8 bg-white bg-opacity-60 rounded-lg'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
