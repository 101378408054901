import { useEffect } from 'react';
import level1Btn from 'assets/images/learnLetter/level_1_btn.svg';
import level2Btn from 'assets/images/learnLetter/level_2_btn.svg';
import level3Btn from 'assets/images/learnLetter/level_3_btn.svg';
import level4Btn from 'assets/images/learnLetter/level_4_btn.svg';
import { useNavigate } from 'react-router-dom';
import { selectLearnStore, useAuthStore } from 'store';
import Header from 'components/Header';

export default function LearnTopic() {
  const navigate = useNavigate();
  const { userData } = useAuthStore();
  const { learnTopicLevel, setLearnTopicLevel, learnType } = selectLearnStore();

  const handleNavigation = (level) => {
    setLearnTopicLevel(level);
    if (learnType === 'letter' || learnType === 'word') {
      navigate('/learn/letter');
    } else if (learnType === 'sentence' || learnType === 'guess') {
      navigate('/learn/dialog');
    } else {
      navigate('/learn');
    }
  };

  const levelImages = [level1Btn, level2Btn, level3Btn, level4Btn];

  return (
    <div className='flex flex-col w-full h-full items-center'>
      <div className='flex flex-col w-full h-full'>
        {/* 헤더 */}
        <Header />
        <div className='flex flex-col w-full items-center h-full'>
          <div className='flex flex-row box-border justify-between w-full h-full mb-10 max-w-[1024px]'>
            <div className='flex flex-col items-center justify-end bg-white bg-opacity-60 rounded-lg shadow-[0_0_12px_#0000001A] w-[300px] h-full py-3'>
              <div className='flex flex-col items-center text-base font-medium p-4 bg-white shadow-[0_0_12px_#0000001A] rounded-lg'>
                <span>
                  <span className='text-[#FF8E0B]'>어떤주제</span>를
                  연습하고싶나요?
                </span>
                <span>함께 시작해봐요!</span>
              </div>
            </div>
            <div className='flex flex-col w-full items-center gap-5'>
              {[1, 2, 3, 4].map((level, index) => (
                <button
                  key={level}
                  onClick={() => handleNavigation(level)}
                  className='relative flex flex-1 justify-start'
                >
                  <img src={levelImages[index]} alt={`level-${level}`} />
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
