import React, { useEffect } from 'react';
import { usePopupStore } from 'store';

const Popup = () => {
  const {
    character,
    errorMessage,
    showError,
    clearError,
    errorCancel,
    checkMessage,
    checkCancel,
    showCheck,
    clearCheck,
    popup,
  } = usePopupStore();

  useEffect(() => {
    if (showError) {
      const timer = setTimeout(() => {
        clearError();
      }, 3000); // 3초 후 에러 메시지 자동 제거
      return () => clearTimeout(timer);
    }
  }, [showError, clearError]);

  useEffect(() => {
    if (showCheck) {
      const timer = setTimeout(() => {
        clearCheck();
      }, 3000); // 3초 후 체크 메시지 자동 제거
      return () => clearTimeout(timer);
    }
  }, [showCheck, clearCheck]);

  if (!showError && !showCheck) return null;

  return (
    <div
      className='absolute bottom-24 left-1/2 transform -translate-x-1/2 rounded flex items-center whitespace-nowrap w-full justify-center'
      style={{ zIndex: 1000 }}
    >
      <img
        src={character}
        alt='character'
        style={{ width: '50px', marginRight: '10px' }}
      />
      {popup === 1 ? (
        <div className='relative bg-[#FFF0F0] text-[#42c72a] font-bold p-4 md:p-4 rounded-2xl border border-[#42c72a]'>
          <p className='text-base md:text-xl'>{checkMessage}</p>
          <div className='absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-full w-0 h-0 border-t-[10px] border-t-transparent border-b-[10px] border-b-transparent border-r-[10px] border-r-[#42c72a]'></div>
          <div className='absolute -top-5 -right-5' onClick={clearCheck}>
            <img src={checkCancel} alt='Error Cancel' className='p-2' />
          </div>
        </div>
      ) : (
        <div className='relative bg-[#FFF0F0] text-[#E71000] font-bold p-4 md:p-4 rounded-2xl border border-[#E71000]'>
          <p className='text-base md:text-xl'>{errorMessage}</p>
          <div className='absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-full w-0 h-0 border-t-[10px] border-t-transparent border-b-[10px] border-b-transparent border-r-[10px] border-r-[#E71000]'></div>
          <div className='absolute -top-5 -right-5' onClick={clearError}>
            <img src={errorCancel} alt='Error Cancel' className='p-2' />
          </div>
        </div>
      )}
    </div>
  );
};

export default Popup;
