import axios from 'axios';
import { API_URL } from 'config';
import { useAuthStore } from 'store';
import { useNavigate } from 'react-router-dom';
import { usePopupStore } from 'store';

export const useApi = () => {
  const { accessToken, setAccessToken, clearAccessToken } = useAuthStore();
  const { setError } = usePopupStore();
  const navigate = useNavigate();

  // axios 인스턴스 생성
  const api = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true, // 쿠키 전송 활성화 (refreshToken)
  });

  // 요청 Interceptor
  api.interceptors.request.use(
    (config) => {
      if (accessToken) {
        // accessToken이 있을 때 Authorization 헤더 추가
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // 응답 Interceptor
  api.interceptors.response.use(
    (response) => response, // 정상 응답일 경우 바로 리턴
    async (error) => {
      const { status, config } = error.response || {};
      const originalRequest = config; // 원래의 요청 저장

      // 401 Unauthorized 에러 처리: 토큰 재발급 로직
      if (status === 401 && !originalRequest._retry) {
        originalRequest._retry = true; // 무한 루프 방지
        try {
          // 토큰 재발급 요청
          const refreshResponse = await api.post(
            '/v1/auth/access-token-reissue'
          );

          if (refreshResponse.data.result === 'SUCCESS') {
            const newAccessToken = refreshResponse.data.data.accessToken;
            setAccessToken(newAccessToken); // 새로운 accessToken 저장

            // 재발급된 토큰으로 Authorization 헤더 업데이트
            originalRequest.headers[
              'Authorization'
            ] = `Bearer ${newAccessToken}`;
            setError('잘못된 접근입니다.');
            // 원래의 요청 다시 시도
            return api(originalRequest);
          } else {
            throw new Error('리프레시 토큰이 유효하지 않습니다.');
          }
        } catch (refreshError) {
          setError('세션이 만료되었습니다. 다시 로그인해주세요.');
          clearAccessToken(); // 토큰 정보 초기화
          navigate('/auth/login', { replace: true }); // 로그인 페이지로 이동
          return Promise.reject(refreshError);
        }
      }

      // 400 Bad Request 에러 처리
      if (status === 400) {
        if (!accessToken) {
          clearAccessToken();
          setError(
            '로그인 유효기간이 만료되었습니다! 로그인을 다시 진행해주세요.'
          );
          navigate('/auth/login', { replace: true });
        } else {
          setError('잘못된 요청입니다! 다시 시도해주세요!');
        }
      }

      // 403 Forbidden 에러 처리 (권한 없음)
      if (status === 403) {
        clearAccessToken();
        setError('세션이 만료되었습니다. 다시 로그인해주세요.');
        navigate('/auth/login', { replace: true });
      }

      return Promise.reject(error); // 에러를 다시 던져서 에러 처리 블록으로 넘김
    }
  );

  return api; // axios 인스턴스 리턴
};
