// components/Header.js
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store';
import { useApi } from 'services/api';
import { selectLearnStore } from 'store';

const learnTypeTitles = {
  letter: '알파벳',
  word: '단어',
  sentence: '문장',
  guess: '추론하기',
};

export default function Header() {
  const { userData, clearAccessToken } = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();
  const api = useApi();
  const { learnType } = selectLearnStore();

  const handleLogout = async () => {
    try {
      const response = await api.post('/v1/auth/logout');
      console.log(response);

      if (response.data.result === 'SUCCESS') {
        clearAccessToken(); // 토큰 및 사용자 정보 초기화
        navigate('/auth/login', { replace: true }); // 로그인 페이지로 리다이렉트
      } else {
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <header className='flex flex-row items-center justify-between mb-4'>
      <button
        onClick={handleBack}
        className='flex flex-row items-center gap-x-5'
      >
        {location.pathname !== '/' && (
          <img
            src={require('assets/icons/common/back-btn.svg').default}
            width={44}
            height={46}
            alt='back button'
          />
        )}
        <h3 className='text-base font-bold md:text-2xl text-[#990000]'>
          {location.pathname.includes('/learn')
            ? learnTypeTitles[learnType] || '영어학습'
            : ''}
        </h3>
      </button>
      <div className='flex flex-row gap-x-5'>
        <div className='flex flex-row items-center gap-x-2'>
          <img
            src={require('assets/icons/common/user-level.svg').default}
            width={28}
            height={28}
            alt='level icon'
            className='w-[22px] md:w-[28px]'
          />
          <h3 className='text-[#990000] font-extrabold text-base md:text-xl'>
            {userData?.displayName === ''
              ? `${userData.schoolName} ${userData.studentGradeLevel}학년 ${userData.studentClassName}반 ${userData.studentNumber}번`
              : userData.displayName}
            님
          </h3>
        </div>
        <div
          className='flex flex-row items-center gap-x-2 border-[3px] border-[#FFFFFF] py-[5px] px-3 rounded-xl'
          onClick={handleLogout}
        >
          <img
            src={require('assets/icons/common/logout-time.svg').default}
            width={28}
            height={28}
            alt='logout icon'
            className='w-[22px] md:w-[28px]'
          />
          <h3 className='text-[#990000] font-extrabold text-base md:text-xl'>
            Logout
          </h3>
        </div>
      </div>
    </header>
  );
}
