import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from 'config';
import axios from 'axios';
import { usePopupStore } from 'store';
import { useAuthStore } from 'store';
import { useApi } from 'services/api';
import { motion } from 'framer-motion';

export default function Login() {
  const [step, setStep] = useState(0);

  const { setError } = usePopupStore();
  const { clearAccessToken } = useAuthStore();

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    if (step > 0) {
      setStep((prevStep) => prevStep - 1);
    }
    clearAccessToken();
  };

  return (
    <div className='flex flex-col w-full h-full'>
      {/* 헤더 */}
      <div className='flex flex-row justify-between items-center mb-4'>
        <button
          onClick={handlePreviousStep}
          className='flex flex-row items-center gap-x-5'
        >
          <img
            src={require('../../assets/icons/common/back-btn.svg').default}
            width={44}
            height={46}
            alt='back button'
          />
          <h3 className='text-base md:text-2xl text-[#990000] font-bold'>
            로그인
          </h3>
        </button>
      </div>
      <LoginStep
        setError={setError}
        step={step}
        handleNextStep={handleNextStep}
        handlePreviousStep={handlePreviousStep}
      />
    </div>
  );
}

function LoginStep({ setError, step, handleNextStep, handlePreviousStep }) {
  const [loginForm, setLoginForm] = useState({
    educationOfficeName: '',
    schoolType: '',
    schoolName: '',
  });
  const [isFace, setIsFace] = useState(0);
  const [faceData, setFaceData] = useState('');
  const [saveGroupInfo, setSaveGroupInfo] = useState(false); // 그룹 정보 저장 상태
  const [autoLogin, setAutoLogin] = useState(false); // 자동 로그인 상태

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setLoginForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  // 그룹정보 저장 체크박스
  const handleCheckboxChange = (e) => {
    setSaveGroupInfo(e.target.checked);
  };

  // 자동로그인 체크박스
  const handleAutoLoginChange = (e) => {
    setAutoLogin(e.target.checked);
    if (e.target.checked) {
      setSaveGroupInfo(true); // 자동로그인 선택 시 그룹 정보 저장도 자동 선택
    }
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <LoginForm
            loginForm={loginForm}
            handleNextStep={handleNextStep}
            autoLogin={autoLogin}
            saveGroupInfo={saveGroupInfo}
            handleCheckboxChange={handleCheckboxChange}
            handleAutoLoginChange={handleAutoLoginChange}
            setError={setError}
            handleChangeData={handleChangeData}
            setLoginForm={setLoginForm}
          />
        );
      case 1:
        return (
          <LoginFace
            loginForm={loginForm}
            handlePreviousStep={handlePreviousStep}
            isFace={isFace}
            setError={setError}
            setIsFace={setIsFace}
            setFaceData={setFaceData}
            faceData={faceData}
          />
        );
      default:
        return <div>잘못된 단계입니다.</div>;
    }
  };

  return (
    <div className='flex flex-col w-full items-center h-full justify-center bg-white bg-opacity-60 shadow-[0_0_12px_rgba(0,0,0,0.1)] rounded-2xl overflow-y-scroll'>
      {renderStep()}
    </div>
  );
}

function LoginForm({
  loginForm,
  handleNextStep,
  autoLogin,
  saveGroupInfo,
  handleCheckboxChange,
  handleAutoLoginChange,
  setError,
  handleChangeData,
  setLoginForm,
}) {
  const [schoolData, setSchoolData] = useState([]);
  const [selectedEducationOfficeName, setSelectedEducationOfficeName] =
    useState('');
  const [selectedSchoolType, setSelectedSchoolType] = useState('');
  const navigate = useNavigate();
  const api = useApi();

  const [showSearchPopup, setShowSearchPopup] = useState(false); // 팝업 상태
  const [schoolList, setSchoolList] = useState([]); // 검색된 학교 리스트
  const [searchKeyword, setSearchKeyword] = useState(''); // 검색할 초성
  const [filteredSchools, setFilteredSchools] = useState([]); // 필터링된 학교 리스트

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (
      loginForm.educationOfficeName === '' ||
      loginForm.schoolType === '' ||
      loginForm.schoolName === ''
    ) {
      setError('로그인 할 학교를 선택해주세요!');
    } else {
      handleNextStep();
      if (saveGroupInfo) {
        localStorage.setItem(
          'groupInfo',
          JSON.stringify({
            educationOfficeName: loginForm.educationOfficeName,
            schoolType: loginForm.schoolType,
            schoolName: loginForm.schoolName,
          })
        );
      } else {
        localStorage.removeItem('groupInfo');
      }
    }
  };

  useEffect(() => {
    const fetchSchoolData = async () => {
      let filePath;
      if (loginForm.schoolType === '초') {
        filePath = '/schoolData/output_elementary.json';
      } else if (loginForm.schoolType === '중') {
        filePath = '/schoolData/output_middle.json';
      } else if (loginForm.schoolType === '고') {
        filePath = '/schoolData/output_high.json';
      }

      if (filePath) {
        try {
          const response = await fetch(filePath);
          const data = await response.json();

          const selectedSchoolData = data.find(
            (item) => item.educationOfficeName === selectedEducationOfficeName
          );

          if (selectedSchoolData) {
            setSchoolList(selectedSchoolData.schoolName);
          } else {
            setSchoolList([]);
          }
        } catch (error) {
          console.error('JSON 파일을 가져오는 중 오류 발생:', error);
        }
      }
    };

    // schoolType이 변경될 때마다 해당하는 JSON 파일 로드
    if (loginForm.schoolType) {
      fetchSchoolData();
    }
  }, [loginForm.schoolType]);

  // 초성을 추출하는 함수
  const getChosung = (char) => {
    const initialConsonants = [
      'ㄱ',
      'ㄲ',
      'ㄴ',
      'ㄷ',
      'ㄸ',
      'ㄹ',
      'ㅁ',
      'ㅂ',
      'ㅃ',
      'ㅅ',
      'ㅆ',
      'ㅇ',
      'ㅈ',
      'ㅉ',
      'ㅊ',
      'ㅋ',
      'ㅌ',
      'ㅍ',
      'ㅎ',
    ];
    const code = char.charCodeAt(0) - 44032;
    if (code < 0 || code > 11171) return char; // 한글 범위 밖의 문자는 그대로 반환
    const chosungIndex = Math.floor(code / 588);
    return initialConsonants[chosungIndex];
  };

  // 여러 글자의 초성을 추출하는 함수
  const getChosungString = (text) => {
    return text
      .split('') // 글자 하나하나를 배열로 분리
      .map((char) => getChosung(char)) // 각 글자의 초성 추출
      .join(''); // 다시 문자열로 합침
  };

  useEffect(() => {
    if (loginForm.schoolType) {
      setSelectedSchoolType(loginForm.schoolType);
    }
    if (loginForm.educationOfficeName) {
      setSelectedEducationOfficeName(loginForm.educationOfficeName);
    }
  }, [loginForm]);

  useEffect(() => {
    if (searchKeyword === '') {
      // 초성이 없으면 전체 리스트를 표시
      setFilteredSchools(schoolList);
    } else {
      // 입력된 초성의 길이만큼 학교 이름의 초성을 추출하여 필터링
      const filtered = schoolList.filter((schoolName) => {
        const schoolChosung = getChosungString(schoolName); // 학교 이름의 초성 문자열 추출
        return schoolChosung.includes(searchKeyword); // 초성 문자열이 searchKeyword로 시작하는지 확인
      });
      setFilteredSchools(filtered);
    }
  }, [searchKeyword, schoolList]);

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
        const getSchoolData = await api.get(
          `${API_URL}/v1/auth/schools/selectable`
        );
        if (getSchoolData.data.result === 'SUCCESS') {
          setSchoolData(getSchoolData.data.data);
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchSchoolData();
  }, []);

  useEffect(() => {
    if (selectedEducationOfficeName) {
      setSelectedSchoolType('');
      setLoginForm((prev) => ({
        ...prev,
        schoolType: '',
        schoolName: '',
      }));
    }
  }, [selectedEducationOfficeName]);

  useEffect(() => {
    if (selectedSchoolType) {
      setLoginForm((prev) => ({
        ...prev,
        schoolName: '',
      }));
    }
  }, [selectedSchoolType]);

  const openSearchPopup = () => {
    setShowSearchPopup(true);
  };

  // 초성 클릭 시 호출되는 함수
  const handleChosungClick = async (chosung) => {
    setSearchKeyword((prevKeyword) => prevKeyword + chosung);
  };

  // 학교명 선택 시 호출되는 함수
  const handleSchoolSelect = (schoolName) => {
    setSearchKeyword('');
    setLoginForm((prev) => ({
      ...prev,
      schoolName: schoolName,
    }));
    setShowSearchPopup(false); // 팝업 닫기
  };

  const handleBackspaceClick = () => {
    setSearchKeyword((prevKeyword) => prevKeyword.slice(0, -1)); // 마지막 글자 하나 제거
  };

  // 중복 제거
  const uniqueByKey = (array, key) => {
    return [...new Map(array.map((item) => [item[key], item])).values()];
  };

  const filteredSchoolTypes = schoolData.filter(
    (school) => school.educationOfficeName === selectedEducationOfficeName
  );

  const uniqueSchoolTypes = uniqueByKey(filteredSchoolTypes, 'schoolType');

  return (
    <div className='flex flex-col w-full items-center justify-center h-full'>
      <div className='relative flex flex-col w-full h-full rounded-2xl justify-center items-center'>
        <form onSubmit={onFormSubmit}>
          <div className='flex flex-row w-[400px] justify-between items-center py-3'>
            <label>교육청</label>
            <div className='relative w-64'>
              <select
                value={selectedEducationOfficeName}
                onChange={(e) => {
                  setSelectedEducationOfficeName(e.target.value);
                  handleChangeData(e);
                }}
                name='educationOfficeName'
                className='py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none'
              >
                <option value=''>선택</option>
                {uniqueByKey(schoolData, 'educationOfficeName').map(
                  (school, idx) => {
                    return (
                      <option
                        key={`education-office-${idx}`}
                        value={school.educationOfficeName}
                      >
                        {school.educationOfficeName}
                      </option>
                    );
                  }
                )}
              </select>
              <div className='absolute pointer-events-none inset-y-0 right-0 flex items-center px-4'>
                <img
                  src={
                    require('../../assets/icons/common/arrow-down.svg').default
                  }
                  alt=''
                />
              </div>
            </div>
          </div>
          <div className='flex flex-row w-[400px] justify-between items-center py-3'>
            <label>초/중/고</label>
            <div className='relative w-64'>
              <select
                onChange={(e) => {
                  setSelectedSchoolType(e.target.value);
                  handleChangeData(e);
                }}
                value={selectedSchoolType}
                name='schoolType'
                className='py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none'
              >
                <option value=''>선택</option>
                {uniqueSchoolTypes.map((school, idx) => (
                  <option key={`schoolType-${idx}`} value={school.schoolType}>
                    {school.schoolType}
                  </option>
                ))}
              </select>
              <div className='absolute pointer-events-none inset-y-0 right-0 flex items-center px-4'>
                <img
                  src={
                    require('../../assets/icons/common/arrow-down.svg').default
                  }
                  alt=''
                />
              </div>
            </div>
          </div>

          <div className='flex flex-row w-[400px] justify-between items-center py-3'>
            <label>학교명</label>
            <div className='relative w-64'>
              <input
                type='text'
                name='schoolName'
                onClick={openSearchPopup}
                readOnly
                value={loginForm.schoolName}
                className='py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none cursor-pointer'
              />

              {/* 팝업창 */}
              {showSearchPopup && (
                <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
                  <div className='relative bg-white rounded-xl p-10 flex flex-col items-center gap-y-4'>
                    <h3 className='text-xl mb-4'>학교명 초성 검색</h3>

                    {/* 초성 버튼 */}
                    <div className='grid grid-cols-7 gap-2 mb-4'>
                      {[
                        'ㄱ',
                        'ㄲ',
                        'ㄴ',
                        'ㄷ',
                        'ㄸ',
                        'ㄹ',
                        'ㅁ',
                        'ㅂ',
                        'ㅃ',
                        'ㅅ',
                        'ㅆ',
                        'ㅇ',
                        'ㅈ',
                        'ㅉ',
                        'ㅊ',
                        'ㅋ',
                        'ㅌ',
                        'ㅍ',
                        'ㅎ',
                      ].map((chosung) => (
                        <button
                          key={chosung}
                          type='button'
                          onClick={() => handleChosungClick(chosung)}
                          className='py-2 px-4 bg-gray-200 rounded-lg'
                        >
                          {chosung}
                        </button>
                      ))}
                    </div>
                    <div className='flex flex-col w-[50%]'>
                      <div className='flex flex-row'>
                        <input
                          type='text'
                          value={searchKeyword}
                          readOnly
                          className='w-[90%] overflow-scroll'
                        />

                        <button
                          type='button'
                          onClick={handleBackspaceClick}
                          className='w-[10%]'
                        >
                          {'<'}
                        </button>
                      </div>
                      <div className='w-full h-[2px] bg-black' />
                    </div>

                    {/* 검색된 학교 리스트 */}
                    <div className='flex flex-col h-64 overflow-y-auto w-[500px]'>
                      {filteredSchools.length > 0 ? (
                        filteredSchools.map((school, idx) => (
                          <div
                            key={idx}
                            className='py-2 px-4 hover:bg-gray-100 cursor-pointer'
                            onClick={() => handleSchoolSelect(school)}
                          >
                            {school}
                          </div>
                        ))
                      ) : (
                        <div className='py-2 text-center'>학교가 없습니다</div>
                      )}
                    </div>

                    {/* 닫기 버튼 */}
                    <button
                      onClick={() => {
                        setSearchKeyword('');
                        setShowSearchPopup(false);
                      }}
                      className='absolute top-4 right-4 text-gray-500 text-2xl'
                    >
                      X
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='flex flex-row items-center py-3 gap-x-10'>
            <div className='flex flex-row items-center gap-x-4'>
              <div className='relative flex justify-center items-center'>
                <input
                  type='checkbox'
                  id='saveGroup'
                  checked={saveGroupInfo}
                  onChange={handleCheckboxChange}
                  className='appearance-none w-8 h-8 border-[2px] bg-white border-[#BFBFBF] rounded-lg checked:bg-[#FE9D13] checked:border-[#ffc97c] focus:outline-none'
                />
                <div className='absolute pointer-events-none flex items-center justify-center'>
                  <img
                    src={require('../../assets/icons/common/check.svg').default}
                    alt=''
                    width={19}
                  />
                </div>
              </div>
              <label className='text-base' htmlFor='saveGroup'>
                그룹 정보 저장
              </label>
            </div>
            <div className='flex flex-row items-center gap-x-4'>
              <div className='relative flex justify-center items-center'>
                <input
                  type='checkbox'
                  id='autoLogin'
                  checked={autoLogin}
                  onChange={handleAutoLoginChange}
                  className='appearance-none w-8 h-8 border-[2px] bg-white border-[#BFBFBF] rounded-lg checked:bg-[#FE9D13] checked:border-[#ffc97c] focus:outline-none'
                />
                <div className='absolute pointer-events-none flex items-center justify-center'>
                  <img
                    src={require('../../assets/icons/common/check.svg').default}
                    alt=''
                    width={19}
                  />
                </div>
              </div>
              <label className='text-base' htmlFor='autoLogin'>
                자동 로그인
              </label>
            </div>
          </div>
          <div className='relative w-full my-3'>
            <button
              type='submit'
              className='flex w-full py-[10px] rounded-xl bg-gradient-to-b from-[#B8DE55] to-[#91CA00] text-white items-center justify-center gap-x-2'
            >
              <img
                src={require('../../assets/icons/common/face-id.svg').default}
                alt=''
              />
              <span className='font-extrabold'>얼굴인식 로그인</span>
            </button>
            <div className='absolute inset-0 rounded-xl border-[2px] border-white opacity-50 pointer-events-none'></div>
          </div>
        </form>
        <div
          className='absolute bottom-10 flex justify-end'
          onClick={() => {
            navigate('/auth/signup');
          }}
        >
          <button className='flex w-full py-3 px-8 rounded-full bg-gradient-to-b from-[#2EB2ED] to-[#1792C9] text-white'>
            <span className='font-extrabold text-2xl'>회원 가입</span>
          </button>
          <div className='absolute inset-0 rounded-full border-[2px] border-white opacity-50 pointer-events-none'></div>
        </div>
      </div>
    </div>
  );
}

function LoginFace({
  loginForm,
  handlePreviousStep,
  isFace,
  setError,
  setIsFace,
}) {
  const userSchool = `${loginForm.educationOfficeName} ${loginForm.schoolName}`;

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null);
  const [loginUser, setLoginUser] = useState(null);
  const { setAccessToken, accessToken } = useAuthStore(); // accessToken 저장
  const api = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    setIsFace(0);
  }, []);

  useEffect(() => {
    const startVideo = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });

        streamRef.current = stream; // 캠 스트림 저장

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }

        setTimeout(() => {
          captureImage();
        }, 3000);
      } catch (error) {
        console.error('Error accessing webcam', error);
        setError('웹캠에 접근할 수 없습니다.');
      }
    };

    if (isFace === 0) {
      startVideo();
    }

    // 캠 스트림 종료 로직 추가
    return () => {
      stopVideoStream(); // 함수로 추출해서 스트림 중지
    };
  }, [setError, videoRef, isFace]);

  const stopVideoStream = () => {
    if (streamRef.current) {
      const tracks = streamRef.current.getTracks();
      tracks.forEach((track) => track.stop());
      streamRef.current = null; // 스트림 해제
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null; // 비디오 요소에서 스트림 해제
    }
  };

  const captureImage = () => {
    if (!videoRef.current || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const video = videoRef.current;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const imageData = canvas.toDataURL('image/jpeg');

    sendImageToServer(imageData);
  };

  const sendImageToServer = async (imageData) => {
    const formData = new FormData();
    const imageFile = dataURLtoFile(imageData, 'captured_face.jpeg');

    formData.append('faceImageFile', imageFile);
    Object.keys(loginForm).forEach((key) => {
      formData.append(key, loginForm[key]);
    });

    const postData = async () => {
      try {
        const response = await api.post(`${API_URL}/v1/auth/login`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        });

        if (response.data.result === 'SUCCESS') {
          const accessToken = response.data.data.accessToken;
          setAccessToken(accessToken);
          setIsFace(1);
        } else {
          throw new Error('Failed');
        }
      } catch (e) {
        setIsFace(2);
        setError('인증에 실패하였습니다! 다시 시도해보세요');
        setAccessToken(false);
      }
      stopVideoStream();
    };
    postData();
  };

  const onSubmitLogin = () => {
    if (isFace === 1 && loginUser !== null) {
      navigate('/');
    } else {
      setError('얼굴 인식을 먼저 진행해주세요!');
    }
  };

  const handleBackClick = () => {
    if (streamRef.current) {
      const tracks = streamRef.current.getTracks();
      tracks.forEach((track) => track.stop());
      streamRef.current = null; // 스트림 해제
    }
    handlePreviousStep();
  };

  const handleRetry = () => {
    setIsFace(0); // 얼굴 인식 상태 초기화
  };

  // accessToken이 변경되면 fetchUserInfo 실행
  useEffect(() => {
    if (accessToken) {
      fetchUserInfo();
    }
  }, [accessToken]);

  const fetchUserInfo = async () => {
    try {
      const response = await api.get('/v1/member/info');
      setLoginUser(response.data.data);
    } catch (e) {
      console.error('사용자 정보 요청 실패', e);
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  return (
    <div className='flex flex-col w-full items-center justify-center h-full'>
      <div className='relative flex flex-col w-full h-full rounded-2xl items-center py-10'>
        <div className='flex flex-col gap-y-4 items-center'>
          {/* 로그인 할 학교 */}
          <div className='flex flex-row items-center gap-x-6'>
            <h3 className='font-bold'>{userSchool}</h3>
            <button
              onClick={handleBackClick}
              className='bg-gradient-to-b from-[#B8DE55] to-[#91CA00] text-white py-2 px-4 rounded-lg font-semibold'
            >
              수정
            </button>
          </div>
          {/* 사용자 캠 화면 스캔 */}
          <div className='relative flex flex-col items-center gap-y-4'>
            <div className='relative w-full h-full flex items-center justify-center'>
              <video
                ref={videoRef}
                autoPlay
                playsInline
                className='w-[240px] h-[240px] object-cover rounded-xl z-10'
              />
              <canvas ref={canvasRef} className='hidden' />
              {isFace === 0 && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  transition={{
                    delay: 2,
                    duration: 1,
                  }}
                  className='absolute w-[260px] h-[8px] shadow-[0_0_20px_#2EB2ED] rounded-full bg-[#2EB2ED] z-30 animate-scan'
                ></motion.div>
              )}

              <div className='flex absolute'>
                {isFace === 0 ? (
                  <img
                    src={
                      require('assets/icons/common/face-character.svg').default
                    }
                    alt=''
                    className='w-[240px] h-[240px]'
                  />
                ) : isFace === 1 ? (
                  <img
                    src={
                      require('assets/icons/common/face-character-success.svg')
                        .default
                    }
                    alt=''
                    className='w-[240px] h-[240px]'
                  />
                ) : (
                  <img
                    src={
                      require('assets/icons/common/face-character-failed.svg')
                        .default
                    }
                    alt=''
                    className='w-[240px] h-[240px]'
                  />
                )}
              </div>
            </div>
            <div className='flex flex-col items-center gap-y-4'>
              {isFace === 0 ? (
                <>
                  <h5 className='text-2xl font-semibold'>인식 중</h5>
                  <span className='text-lg'>정면 카메라를 바라봐주세요.</span>
                </>
              ) : isFace === 1 ? (
                <>
                  <h5 className='text-2xl font-semibold'>인식 성공</h5>
                  <span className='text-lg'>환영합니다!</span>
                </>
              ) : (
                <>
                  <h5 className='text-2xl font-semibold'>인식 실패</h5>
                  <span className='text-lg'>다시 인식해주세요.</span>
                </>
              )}
            </div>
            {isFace === 2 && (
              <div className='flex flex-row items-center'>
                <button
                  className='flex w-full py-2 px-6 rounded-full bg-[#A4A4A4] text-white'
                  onClick={handleRetry}
                >
                  재인식
                </button>
              </div>
            )}

            {/* 사용자 정보 확인 */}
            <div className='flex flex-col gap-y-3'>
              <span className='text-lg'>아래 정보를 확인하세요.</span>
              <div className='flex justify-center bg-gradient-to-b from-[#FE9D13] to-[#FF7A00] text-white py-6 px-28 rounded-full'>
                {accessToken && loginUser ? (
                  <span className='text-base'>{`${loginUser.studentGradeLevel}학년 ${loginUser.studentClassName}반 ${loginUser.studentNumber}번 ${loginUser.displayName}님`}</span>
                ) : accessToken === false ? (
                  <span className='text-base'>
                    사용자 정보를 불러오는데 실패하였습니다.
                  </span>
                ) : (
                  <span className='text-base'>
                    사용자 정보를 불러오는 중입니다..
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-row gap-x-5 py-6'>
          <div className='relative flex justify-end'>
            <button
              className='flex w-full py-3 px-8 rounded-full bg-[#A4A4A4] text-white'
              onClick={handleBackClick}
            >
              <span className='font-extrabold text-2xl'>아니요</span>
            </button>
            <div className='absolute inset-0 rounded-full border-[2px] border-white opacity-50 pointer-events-none'></div>
          </div>
          <div className='relative flex justify-end'>
            <button
              className='flex w-full py-3 px-8 rounded-full bg-gradient-to-b from-[#2EB2ED] to-[#1792C9] text-white'
              onClick={onSubmitLogin}
            >
              <span className='font-extrabold text-2xl'>네</span>
            </button>
            <div className='absolute inset-0 rounded-full border-[2px] border-white opacity-50 pointer-events-none'></div>
          </div>
        </div>
      </div>
    </div>
  );
}
