import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='flex flex-col items-center justify-center h-screen'>
      <h1 className='text-4xl font-bold'>404 - Page Not Found</h1>
      <p className='text-lg mt-4'>잘못된 요청 페이지입니다.</p>
      <Link to='/' className='mt-6 text-blue-500'>
        홈으로 돌아가기
      </Link>
    </div>
  );
};

export default NotFound;
