import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Home from 'pages/Home';
// import Learn from './pages/Learn';
import Login from 'pages/Login';
// import dayjs from 'dayjs';
// import { apiUrl } from './config';
// import axios from 'axios';
import SignUp from 'pages/Signup';
// import PrivateRoute from './PrivateRoute'; // 권한 보호 컴포넌트
// import LearnTopic from './pages/LearnTopic';
// import Dialog from './pages/Dialog';
// import Letter from './pages/Letter';
import DefaultLayout from 'components/DefaultLayout';
import NotFound from 'pages/Error/404';
import AuthLayout from 'components/AuthLayout';
import PrivateLayout from 'components/PrivateLayout';
import Popup from 'components/Popup';
import Learn from 'pages/Learn';
import LearnTopic from 'pages/LearnTopic';
import Dialog from 'pages/Dialog';
import Letter from 'pages/Letter';

import { useEnvironmentStore } from 'store';

function App() {

  // 실행 환경 구분
  const setEnvironment = useEnvironmentStore((state) => state.setEnvironment);
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!localStorage.getItem("env") || params.get("env")) {
      console.warn('setting Environment'); // TODO:
      if (params.get("env") === 'guide-aos') {
        setEnvironment('robot-android-webview');
      } else {
        setEnvironment('browser');
      }
    }
  }, [setEnvironment]);

  return (
    <Router>
      <Routes>
        <Route path='auth' element={<DefaultLayout />}>
          <Route path='login' element={<Login />} />
          <Route path='signup' element={<SignUp />} />
        </Route>

        <Route element={<AuthLayout />}>
          <Route path='/' element={<DefaultLayout />}>
            <Route index element={<Home />} />
            <Route path='learn' element={<Learn />} />
            <Route path='learn/topic' element={<LearnTopic />} />
            <Route path='learn/dialog' element={<Dialog />} />
            <Route path='learn/letter' element={<Letter />} />
          </Route>
        </Route>

        <Route path='*' element={<NotFound />} />
      </Routes>
      <Popup />
    </Router>
  );
}

export default App;
