import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { API_URL } from 'config';
import { useApi } from 'services/api';
import { usePopupStore } from 'store';
import { useAuthStore } from 'store';
import { motion } from 'framer-motion';

export default function SignUp() {
  const navigate = useNavigate();
  const [requiredFlag, setRequiredFlag] = useState(false);
  const { setError, setCheck } = usePopupStore();
  const [isFace, setIsFace] = useState(0);
  const [step, setStep] = useState(0);

  const [signupForm, setSignupForm] = useState({
    educationOfficeName: '',
    schoolType: '',
    schoolName: '',
    studentGradeLevel: '',
    studentClassName: '',
    studentNumber: '',
    name: '',
    nickname: '',
    termsAccepted: false,
  });

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <SignupInfo
            signupForm={signupForm}
            setSignupForm={setSignupForm}
            requiredFlag={requiredFlag}
            onNextSignup={onNextSignup}
          />
        );
      case 1:
        return (
          <SignupFace
            signupForm={signupForm}
            setIsFace={setIsFace}
            onPrevSignup={onPrevSignup}
            isFace={isFace}
            setError={setError}
            setCheck={setCheck}
          />
        );
      default:
        return null;
    }
  };

  const onNextSignup = (e) => {
    e.preventDefault();
    if (!signupForm.termsAccepted) {
      setError('약관에 동의하지 않으면 서비스 사용이 불가합니다.');
      return;
    }

    if (
      signupForm.educationOfficeName === '' ||
      signupForm.schoolType === '' ||
      signupForm.schoolName === '' ||
      signupForm.studentGradeLevel === '' ||
      signupForm.studentClassName === '' ||
      signupForm.studentNumber === ''
    ) {
      setRequiredFlag(true);
      setError('필수 문항을 선택해주세요.');
      return;
    }

    const UserData = signupForm;

    const postData = async () => {
      try {
        const response = await axios.post(
          `${API_URL}/v1/auth/signup/validation/text`,
          UserData
        );

        if (response.data.result === 'SUCCESS') {
          setStep(step + 1);
        }
      } catch (e) {
        if (e.response.status === 400 && e.response.data.result === 'ERROR') {
          setError('이미 해당 정보로 가입한 사용자가 존재합니다!');
        } else {
          setError('회원가입 양식이 올바르지 않습니다. 다시 시도해주세요');
        }
      }
    };

    postData();
  };

  const onPrevSignup = () => {
    if (step > 0) {
      setStep((prevStep) => prevStep - 1);
    }
  };

  return (
    <div className='flex flex-col w-full h-full'>
      {/* 헤더 */}
      <div className='flex flex-row justify-between items-center mb-4'>
        <div
          className='flex flex-row items-center gap-x-5'
          onClick={() => {
            navigate('/auth/login');
          }}
        >
          <img
            src={require('assets/icons/common/back-btn.svg').default}
            width={44}
            height={46}
            alt='back button'
          />
          <h3 className='text-base md:text-2xl text-[#990000] font-bold'>
            회원가입
          </h3>
        </div>
        <div className='flex flex-row items-center gap-x-5'>
          <div className='flex bg-white w-[200px] md:w-[300px] h-[15px] md:h-[20px] rounded-full'>
            <div
              className={`${
                step === 0 ? 'w-1/2' : 'w-full'
              } bg-gradient-to-b from-[#FE9D13] to-[#FF7A00] rounded-full`}
            ></div>
          </div>
          <h5 className='text-base md:text-xl font-semibold text-[#990000]'>
            STEP {step + 1} <span className='text-[#D37676]'>/ 2</span>
          </h5>
        </div>
      </div>
      {/* 회원가입 폼 */}
      <div className='flex flex-col w-full items-center h-full justify-center bg-white bg-opacity-60 shadow-[0_0_12px_rgba(0,0,0,0.1)] rounded-2xl overflow-y-scroll'>
        {renderStep()}
      </div>
    </div>
  );
}

function SignupInfo({
  setError,
  signupForm,
  setSignupForm,
  requiredFlag,
  onNextSignup,
}) {
  const [schoolData, setSchoolData] = useState([]);
  const [selectedEducationOfficeName, setSelectedEducationOfficeName] =
    useState('');
  const [selectedSchoolType, setSelectedSchoolType] = useState('');
  const [showSearchPopup, setShowSearchPopup] = useState(false); // 팝업 상태
  const [showTermsPopup, setShowTermsPopup] = useState(false); // 약관 팝업 상태
  const [schoolList, setSchoolList] = useState([]); // 검색된 학교 리스트
  const [searchKeyword, setSearchKeyword] = useState(''); // 검색할 초성
  const [filteredSchools, setFilteredSchools] = useState([]); // 필터링된 학교 리스트

  const handleTermsPopup = (bool) => {
    setShowTermsPopup(bool);
  };

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
        const getSchoolData = await axios.get(
          `${API_URL}/v1/auth/schools/selectable`
        );
        if (getSchoolData.data.result === 'SUCCESS') {
          setSchoolData(getSchoolData.data.data);
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchSchoolData();
  }, []);

  useEffect(() => {
    const fetchSchoolData = async () => {
      let filePath;
      if (signupForm.schoolType === '초') {
        filePath = '/schoolData/output_elementary.json';
      } else if (signupForm.schoolType === '중') {
        filePath = '/schoolData/output_middle.json';
      } else if (signupForm.schoolType === '고') {
        filePath = '/schoolData/output_high.json';
      }

      if (filePath) {
        try {
          const response = await fetch(filePath);
          const data = await response.json();

          const selectedSchoolData = data.find(
            (item) => item.educationOfficeName === selectedEducationOfficeName
          );

          if (selectedSchoolData) {
            setSchoolList(selectedSchoolData.schoolName);
          } else {
            setSchoolList([]);
          }
        } catch (error) {
          console.error('JSON 파일을 가져오는 중 오류 발생:', error);
        }
      }
    };

    // schoolType이 변경될 때마다 해당하는 JSON 파일 로드
    if (signupForm.schoolType) {
      fetchSchoolData();
    }
  }, [signupForm.schoolType]);

  useEffect(() => {
    if (signupForm.educationOfficeName) {
      setSelectedEducationOfficeName(signupForm.educationOfficeName);
    }
    if (signupForm.schoolType) {
      setSelectedSchoolType(signupForm.schoolType);
    }
  }, [signupForm.educationOfficeName, signupForm.schoolType]);

  // 초성을 추출하는 함수
  const getChosung = (char) => {
    const initialConsonants = [
      'ㄱ',
      'ㄲ',
      'ㄴ',
      'ㄷ',
      'ㄸ',
      'ㄹ',
      'ㅁ',
      'ㅂ',
      'ㅃ',
      'ㅅ',
      'ㅆ',
      'ㅇ',
      'ㅈ',
      'ㅉ',
      'ㅊ',
      'ㅋ',
      'ㅌ',
      'ㅍ',
      'ㅎ',
    ];
    const code = char.charCodeAt(0) - 44032;
    if (code < 0 || code > 11171) return char; // 한글 범위 밖의 문자는 그대로 반환
    const chosungIndex = Math.floor(code / 588);
    return initialConsonants[chosungIndex];
  };

  const getChosungString = (text) => {
    return text
      .split('') // 글자 하나하나를 배열로 분리
      .map((char) => getChosung(char)) // 각 글자의 초성 추출
      .join(''); // 다시 문자열로 합침
  };

  useEffect(() => {
    if (searchKeyword === '') {
      // 초성이 없으면 전체 리스트를 표시
      setFilteredSchools(schoolList);
    } else {
      // 입력된 초성의 길이만큼 학교 이름의 초성을 추출하여 필터링
      const filtered = schoolList.filter((schoolName) => {
        const schoolChosung = getChosungString(schoolName); // 학교 이름의 초성 문자열 추출
        return schoolChosung.includes(searchKeyword); // 초성 문자열이 searchKeyword로 시작하는지 확인
      });
      setFilteredSchools(filtered);
    }
  }, [searchKeyword, schoolList]);

  useEffect(() => {
    if (selectedEducationOfficeName) {
      setSignupForm((prev) => ({
        ...prev,
        schoolType: '',
        schoolName: '',
      }));
    }
  }, [selectedEducationOfficeName]);

  useEffect(() => {
    if (selectedSchoolType) {
      setSignupForm((prev) => ({
        ...prev,
        schoolName: '',
      }));
    }
  }, [selectedSchoolType]);

  const openSearchPopup = () => {
    setShowSearchPopup(true);
  };

  // 초성 클릭 시 호출되는 함수
  const handleChosungClick = async (chosung) => {
    setSearchKeyword((prevKeyword) => prevKeyword + chosung);
  };

  // 학교명 선택 시 호출되는 함수
  const handleSchoolSelect = (schoolName) => {
    setSearchKeyword('');
    setSignupForm((prev) => ({
      ...prev,
      schoolName: schoolName,
    }));
    setShowSearchPopup(false); // 팝업 닫기
  };

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setSignupForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleBackspaceClick = () => {
    setSearchKeyword((prevKeyword) => prevKeyword.slice(0, -1)); // 마지막 글자 하나 제거
  };

  const uniqueByKey = (array, key) => {
    return [...new Map(array.map((item) => [item[key], item])).values()];
  };

  const filteredSchoolTypes = schoolData.filter(
    (school) => school.educationOfficeName === selectedEducationOfficeName
  );

  const uniqueSchoolTypes = uniqueByKey(filteredSchoolTypes, 'schoolType');

  return (
    <div className='relative flex flex-col w-full h-full rounded-2xl items-center justify-start py-10'>
      <div className='flex flex-col w-2/3 items-center'>
        <div className='flex flex-row w-[400px] justify-between items-center py-3'>
          <label className='font-bold'>교육청</label>
          <div className='relative w-64'>
            <select
              required
              name='educationOfficeName'
              value={signupForm.educationOfficeName}
              onChange={(e) => {
                setSelectedEducationOfficeName(e.target.value);
                handleChangeData(e);
              }}
              className={`py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none ${
                requiredFlag && signupForm.educationOfficeName === ''
                  ? 'border-[#E71000]'
                  : 'border-[#BFBFBF]'
              }`}
            >
              <option value=''>선택</option>
              {uniqueByKey(schoolData, 'educationOfficeName').map(
                (school, idx) => {
                  return (
                    <option
                      key={`education-office-${idx}`}
                      value={school.educationOfficeName}
                    >
                      {school.educationOfficeName}
                    </option>
                  );
                }
              )}
            </select>
            <div className='absolute pointer-events-none inset-y-0 right-0 flex items-center px-4'>
              <img
                src={require('assets/icons/common/arrow-down.svg').default}
                alt=''
              />
            </div>
          </div>
        </div>

        <div className='flex flex-row w-[400px] justify-between items-center py-3'>
          <label className='font-bold'>초/중/고</label>
          <div className='relative w-64'>
            <select
              required
              value={signupForm.schoolType}
              name='schoolType'
              onChange={(e) => {
                setSelectedSchoolType(e.target.value);
                handleChangeData(e);
              }}
              className={`py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none ${
                requiredFlag && signupForm.schoolType === ''
                  ? 'border-[#E71000]'
                  : 'border-[#BFBFBF]'
              }`}
            >
              <option value=''>선택</option>
              {uniqueSchoolTypes.map((school, idx) => (
                <option key={`schoolType-${idx}`} value={school.schoolType}>
                  {school.schoolType}
                </option>
              ))}
            </select>
            <div className='absolute pointer-events-none inset-y-0 right-0 flex items-center px-4'>
              <img
                src={require('assets/icons/common/arrow-down.svg').default}
                alt=''
              />
            </div>
          </div>
        </div>
        <div className='flex flex-row w-[400px] justify-between items-center py-3'>
          <label className='font-bold'>학교명</label>
          <div className='relative w-64'>
            <input
              type='text'
              name='schoolName'
              onClick={openSearchPopup}
              readOnly
              value={signupForm.schoolName}
              className={`py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none cursor-pointer ${
                requiredFlag && signupForm.schoolName === ''
                  ? 'border-[#E71000]'
                  : 'border-[#BFBFBF]'
              }`}
            />

            {/* 팝업창 */}
            {showSearchPopup && (
              <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
                <div className='relative bg-white rounded-xl p-10 flex flex-col items-center gap-y-4'>
                  <h3 className='text-xl mb-4'>학교명 초성 검색</h3>

                  {/* 초성 버튼 */}
                  <div className='grid grid-cols-7 gap-2 mb-4'>
                    {[
                      'ㄱ',
                      'ㄲ',
                      'ㄴ',
                      'ㄷ',
                      'ㄸ',
                      'ㄹ',
                      'ㅁ',
                      'ㅂ',
                      'ㅃ',
                      'ㅅ',
                      'ㅆ',
                      'ㅇ',
                      'ㅈ',
                      'ㅉ',
                      'ㅊ',
                      'ㅋ',
                      'ㅌ',
                      'ㅍ',
                      'ㅎ',
                    ].map((chosung) => (
                      <button
                        key={chosung}
                        type='button'
                        onClick={() => handleChosungClick(chosung)}
                        className='py-2 px-4 bg-gray-200 rounded-lg'
                      >
                        {chosung}
                      </button>
                    ))}
                  </div>
                  <div className='flex flex-col w-[50%]'>
                    <div className='flex flex-row'>
                      <input
                        type='text'
                        value={searchKeyword}
                        readOnly
                        className='w-[90%] overflow-scroll'
                      />

                      <button
                        type='button'
                        onClick={handleBackspaceClick}
                        className='w-[10%]'
                      >
                        {'<'}
                      </button>
                    </div>
                    <div className='w-full h-[2px] bg-black' />
                  </div>

                  {/* 검색된 학교 리스트 */}
                  <div className='flex flex-col h-64 overflow-y-auto w-[500px]'>
                    {filteredSchools.length > 0 ? (
                      filteredSchools.map((school, idx) => (
                        <div
                          key={idx}
                          className='py-2 px-4 hover:bg-gray-100 cursor-pointer'
                          onClick={() => handleSchoolSelect(school)}
                        >
                          {school}
                        </div>
                      ))
                    ) : (
                      <div className='py-2 text-center'>학교가 없습니다</div>
                    )}
                  </div>

                  {/* 닫기 버튼 */}
                  <button
                    onClick={() => {
                      setSearchKeyword('');
                      setShowSearchPopup(false);
                    }}
                    className='absolute top-4 right-4 text-gray-500 text-2xl'
                  >
                    X
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='flex flex-row w-[400px] justify-between items-center py-3'>
          <label className='font-bold'>학년</label>
          <div className='relative w-64'>
            <select
              required
              name='studentGradeLevel'
              value={signupForm.studentGradeLevel}
              onChange={handleChangeData}
              className={`py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none ${
                requiredFlag && signupForm.studentGradeLevel === ''
                  ? 'border-[#E71000]'
                  : 'border-[#BFBFBF]'
              }`}
            >
              <option value=''>선택</option>
              {selectedSchoolType === '초' ? (
                <>
                  <option value='1'>1학년</option>
                  <option value='2'>2학년</option>
                  <option value='3'>3학년</option>
                  <option value='4'>4학년</option>
                  <option value='5'>5학년</option>
                  <option value='6'>6학년</option>
                </>
              ) : (
                <>
                  <option value='1'>1학년</option>
                  <option value='2'>2학년</option>
                  <option value='3'>3학년</option>
                </>
              )}
            </select>
            <div className='absolute pointer-events-none inset-y-0 right-0 flex items-center px-4'>
              <img
                src={require('assets/icons/common/arrow-down.svg').default}
                alt=''
              />
            </div>
          </div>
        </div>
        <div className='flex flex-row w-[400px] justify-between items-center py-3'>
          <label className='font-bold'>반</label>
          <div className='relative w-64'>
            <select
              required
              name='studentClassName'
              value={signupForm.studentClassName}
              onChange={handleChangeData}
              className={`py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none ${
                requiredFlag && signupForm.studentClassName === ''
                  ? 'border-[#E71000]'
                  : 'border-[#BFBFBF]'
              }`}
            >
              <option value=''>선택</option>
              {Array.from({ length: 15 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}반
                </option>
              ))}
            </select>
            <div className='absolute pointer-events-none inset-y-0 right-0 flex items-center px-4'>
              <img
                src={require('assets/icons/common/arrow-down.svg').default}
                alt=''
              />
            </div>
          </div>
        </div>
        <div className='flex flex-row w-[400px] justify-between items-center py-3'>
          <label className='font-bold'>번호</label>
          <div className='relative w-64'>
            <select
              required
              name='studentNumber'
              value={signupForm.studentNumber}
              onChange={handleChangeData}
              className={`py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none ${
                requiredFlag && signupForm.studentNumber === ''
                  ? 'border-[#E71000]'
                  : 'border-[#BFBFBF]'
              }`}
            >
              <option value=''>선택</option>
              {Array.from({ length: 50 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}번
                </option>
              ))}
            </select>
            <div className='absolute pointer-events-none inset-y-0 right-0 flex items-center px-4'>
              <img
                src={require('assets/icons/common/arrow-down.svg').default}
                alt=''
              />
            </div>
          </div>
        </div>
        <div className='flex flex-row w-[400px] justify-between items-center py-3'>
          <label className='flex flex-col'>
            <span className='font-bold'>이름</span>
            <span className='text-sm'>(선택)</span>
          </label>
          <div className='relative w-64'>
            <input
              type='text'
              name='name'
              value={signupForm.name}
              onChange={handleChangeData}
              className='py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none'
            />
          </div>
        </div>
        <div className='flex flex-row w-[400px] justify-between items-center py-3'>
          <label className='flex flex-col'>
            <span className='font-bold'>닉네임</span>
            <span className='text-sm'>(선택)</span>
          </label>
          <div className='relative w-64'>
            <input
              type='text'
              value={signupForm.nickname}
              name='nickname'
              onChange={handleChangeData}
              className='py-3 px-4 w-full rounded-2xl text-gray-400 appearance-none border-[2px] border-[#BFBFBF] focus:outline-none'
            />
          </div>
        </div>

        <div className='flex flex-row items-center py-3 gap-x-10 w-[400px]'>
          <div className='flex flex-row items-center gap-x-4'>
            <div className='relative flex justify-center items-center'>
              <input
                required
                id='term'
                type='checkbox'
                name='termsAccepted'
                readOnly
                checked={signupForm.termsAccepted}
                onClick={() => {
                  if (!signupForm.termsAccepted) {
                    handleTermsPopup(true);
                  } else {
                    setSignupForm((prev) => ({
                      ...prev,
                      termsAccepted: false,
                    }));
                  }
                }}
                className='appearance-none w-8 h-8 border-[2px] bg-white border-[#BFBFBF] rounded-lg checked:bg-[#FE9D13] checked:border-[#ffc97c] focus:outline-none'
              />
              <div className='absolute pointer-events-none flex items-center justify-center'>
                <img
                  src={require('assets/icons/common/check.svg').default}
                  alt=''
                  width={19}
                />
              </div>
            </div>

            <label htmlFor='term' className='text-base'>
              이용약관동의
            </label>
          </div>
        </div>
      </div>

      {/* 이용약관 팝업 */}
      {showTermsPopup && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-30'>
          <div className='relative bg-white rounded-xl p-6 flex flex-col items-center gap-y-4 w-[450px] justify-between'>
            <div className='flex flex-row'>
              <h3 className='text-xl mb-4 font-bold'>이용약관</h3>
              <button
                onClick={() => handleTermsPopup(false)}
                className='absolute top-4 right-4 text-gray-500 text-2xl'
              >
                X
              </button>
            </div>
            <div className='overflow-y-scroll h-60 w-full'>
              <p>
                {/* TODO: 이용약관 수정 */}
                이용약관 내용이용약관 내용이용약관 내용이용약관 내용이용약관
                내용이용약관 내용이용약관 내용이용약관 내용이용약관 내용이용약관
                내용이용약관 내용이용약관 내용이용약관 내용이용약관 내용이용약관
                내용이용약관 내용이용약관 내용이용약관 내용이용약관 내용이용약관
                내용이용약관 내용이용약관 내용이용약관 내용이용약관 내용이용약관
                내용이용약관 내용이용약관 내용이용약관 내용이용약관 내용이용약관
                내용이용약관 내용
              </p>
            </div>

            {/* 동의 버튼 */}
            <div className='relative flex justify-end my-4'>
              <button
                className='flex w-full py-3 px-8 rounded-full bg-gradient-to-b from-[#2EB2ED] to-[#1792C9] text-white'
                onClick={() => {
                  setSignupForm((prev) => ({
                    ...prev,
                    termsAccepted: true,
                  }));
                  handleTermsPopup(false);
                }}
              >
                <span className='font-extrabold text-2xl'>동의</span>
              </button>
              <div className='absolute inset-0 rounded-full border-[2px] border-white opacity-50 pointer-events-none'></div>
            </div>
          </div>
        </div>
      )}

      <div className='pb-10'>
        <div className='relative flex justify-end my-4'>
          <button
            className='flex w-full py-3 px-8 rounded-full bg-gradient-to-b from-[#2EB2ED] to-[#1792C9] text-white'
            onClick={onNextSignup}
          >
            <span className='font-extrabold text-2xl'>다음</span>
          </button>
          <div className='absolute inset-0 rounded-full border-[2px] border-white opacity-50 pointer-events-none'></div>
        </div>
      </div>
    </div>
  );
}

function SignupFace({
  isFace,
  setIsFace,
  setError,
  onPrevSignup,
  signupForm,
  setCheck,
}) {
  const userSchool = `${signupForm.educationOfficeName} ${signupForm.schoolName}`;
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null);
  const [signupFace, setSignupFace] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setIsFace(0);
  }, []);

  useEffect(() => {
    const startVideo = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });

        streamRef.current = stream; // 캠 스트림 저장

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }

        setTimeout(() => {
          captureImage();
        }, 3000);
      } catch (error) {
        console.error('Error accessing webcam', error);
        setError('웹캠에 접근할 수 없습니다.');
      }
    };

    if (isFace === 0) {
      startVideo();
    }

    // 캠 스트림 종료 로직 추가
    return () => {
      stopVideoStream(); // 함수로 추출해서 스트림 중지
    };
  }, [setError, videoRef, isFace]);

  const stopVideoStream = () => {
    if (streamRef.current) {
      const tracks = streamRef.current.getTracks();
      tracks.forEach((track) => track.stop());
      streamRef.current = null; // 스트림 해제
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null; // 비디오 요소에서 스트림 해제
    }
  };

  const captureImage = () => {
    if (!videoRef.current || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const video = videoRef.current;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const imageData = canvas.toDataURL('image/jpeg');

    sendImageToServer(imageData);
  };

  const sendImageToServer = async (imageData) => {
    const faceData = new FormData();
    const imageFile = dataURLtoFile(imageData, 'captured_face.jpeg');

    faceData.append('faceImageFile', imageFile);

    const postData = async () => {
      try {
        const response = await axios.post(
          `${API_URL}/v1/auth/signup/validation/faces`,
          faceData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
          }
        );

        if (response.data.result === 'SUCCESS') {
          setIsFace(1);
          setSignupFace(imageFile);
        } else {
          throw new Error('Failed');
        }
      } catch (e) {
        setIsFace(2);
        setError('인증에 실패하였습니다! 다시 시도해보세요');
      }
      stopVideoStream();
    };
    await postData();
  };

  const onSubmitSignup = () => {
    const formData = new FormData();

    if (signupFace) {
      formData.append('faceImageFile', signupFace);
    } else {
      setError('얼굴 인식을 먼저 진행해주세요!');
      return;
    }

    //form-data의 key값인 signupInfo을 추가(text 유효성 검사 한 데이터의 JSON형태를 문자열로 변환하여 추가)
    formData.append(
      'signupInfo',
      //json형태로 지정 후 append
      new Blob([JSON.stringify(signupForm)], {
        type: 'application/json',
      })
    );

    if (isFace === 1) {
      const postData = async () => {
        try {
          const response = await axios.post(
            `${API_URL}/v1/auth/signup`,
            formData,
            {
              headers: {
                //form-data형태로 전송
                'Content-Type': 'multipart/form-data',
              },
            }
          );

          if (response.data.result === 'SUCCESS') {
            setCheck(
              '등록이 완료되었습니다! 5초 후에 로그인 화면으로 이동됩니다.'
            );
            setTimeout(() => {
              navigate('/auth/login');
            }, 5000);
          } else {
            setError(
              '일시적인 오류로 회원 등록을 못하였습니다. 다시 한번 시도해주세요'
            );
            return;
          }
        } catch (e) {
          console.error(e);
          setError(
            '일시적인 오류로 회원 등록을 못하였습니다. 다시 한번 시도해주세요'
          );
          return;
        }
      };

      postData();
    } else {
      setError('얼굴 인식을 먼저 진행해주세요!');
    }
  };

  const handleBackClick = () => {
    if (streamRef.current) {
      const tracks = streamRef.current.getTracks();
      tracks.forEach((track) => track.stop());
      streamRef.current = null; // 스트림 해제
    }
    onPrevSignup();
  };

  const handleRetry = () => {
    setIsFace(0); // 얼굴 인식 상태 초기화
  };

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  return (
    <div className='flex flex-col w-full items-center justify-center h-full'>
      <div className='relative flex flex-col w-full h-full rounded-2xl items-center py-10'>
        <div className='flex flex-col gap-y-4 items-center'>
          {/* 로그인 할 학교 */}
          <div className='flex flex-row items-center gap-x-6'>
            <h3 className='font-bold'>{userSchool}</h3>
            <button
              onClick={handleBackClick}
              className='bg-gradient-to-b from-[#B8DE55] to-[#91CA00] text-white py-2 px-4 rounded-lg font-semibold'
            >
              수정
            </button>
          </div>
          {/* 사용자 캠 화면 스캔 */}
          <div className='relative flex flex-col items-center gap-y-4'>
            <div className='relative w-full h-full flex items-center justify-center'>
              <video
                ref={videoRef}
                autoPlay
                playsInline
                className='w-[240px] h-[240px] object-cover rounded-xl z-10'
              />
              <canvas ref={canvasRef} className='hidden' />
              {isFace === 0 && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  transition={{
                    delay: 2,
                    duration: 1,
                  }}
                  className='absolute w-[260px] h-[8px] shadow-[0_0_20px_#2EB2ED] rounded-full bg-[#2EB2ED] z-30 animate-scan'
                ></motion.div>
              )}

              <div className='flex absolute'>
                {isFace === 0 ? (
                  <img
                    src={
                      require('assets/icons/common/face-character.svg').default
                    }
                    alt=''
                    className='w-[240px] h-[240px]'
                  />
                ) : isFace === 1 ? (
                  <img
                    src={
                      require('assets/icons/common/face-character-success.svg')
                        .default
                    }
                    alt=''
                    className='w-[240px] h-[240px]'
                  />
                ) : (
                  <img
                    src={
                      require('assets/icons/common/face-character-failed.svg')
                        .default
                    }
                    alt=''
                    className='w-[240px] h-[240px]'
                  />
                )}
              </div>
            </div>
            <div className='flex flex-col items-center gap-y-4'>
              {isFace === 0 ? (
                <>
                  <h5 className='text-2xl font-semibold'>인식 중</h5>
                  <span className='text-lg'>정면 카메라를 바라봐주세요.</span>
                </>
              ) : isFace === 1 ? (
                <>
                  <h5 className='text-2xl font-semibold'>인식 성공</h5>
                  <span className='text-lg'>환영합니다!</span>
                </>
              ) : isFace === 2 ? (
                <>
                  <h5 className='text-2xl font-semibold'>인식 실패</h5>
                  <span className='text-lg'>다시 인식해주세요.</span>
                </>
              ) : null}
            </div>
            {isFace === 2 && (
              <div className='flex flex-row items-center'>
                <button
                  className='flex w-full py-2 px-6 rounded-full bg-[#A4A4A4] text-white'
                  onClick={handleRetry}
                >
                  재인식
                </button>
              </div>
            )}

            {/* 사용자 정보 확인 */}
            <div className='flex flex-col gap-y-3'>
              <span className='text-lg'>아래 정보를 확인하세요.</span>
              <div className='flex justify-center bg-gradient-to-b from-[#FE9D13] to-[#FF7A00] text-white py-6 px-28 rounded-full'>
                <span className='text-base'>
                  {`${signupForm.studentGradeLevel}학년 ${
                    signupForm.studentClassName
                  }반 ${signupForm.studentNumber}번 ${
                    signupForm.nickname !== ''
                      ? signupForm.nickname
                      : signupForm.name !== ''
                      ? signupForm.name
                      : ''
                  }님`}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-row gap-x-5 py-6'>
          <div className='relative flex justify-end'>
            <button
              className='flex w-full py-3 px-8 rounded-full bg-[#A4A4A4] text-white'
              onClick={handleBackClick}
            >
              <span className='font-extrabold text-2xl'>아니요</span>
            </button>
            <div className='absolute inset-0 rounded-full border-[2px] border-white opacity-50 pointer-events-none'></div>
          </div>
          <div className='relative flex justify-end'>
            <button
              className='flex w-full py-3 px-8 rounded-full bg-gradient-to-b from-[#2EB2ED] to-[#1792C9] text-white'
              onClick={onSubmitSignup}
            >
              <span className='font-extrabold text-2xl'>등록</span>
            </button>
            <div className='absolute inset-0 rounded-full border-[2px] border-white opacity-50 pointer-events-none'></div>
          </div>
        </div>
      </div>
    </div>
  );
}
