import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store';
import { useState } from 'react';

export default function Letter() {
  const navigate = useNavigate();
  const { userData, setUserData } = useAuthStore();
  const [words, setWords] = useState([
    { english: 'APPLE', korean: '사과' },
    { english: 'BANANA', korean: '바나나' },
    { english: 'MANGO', korean: '망고' },
  ]);

  // 단어와 번역된 단어 상태
  const [currentIndex, setCurrentIndex] = useState(0); // 현재 단어의 인덱스

  // 왼쪽 버튼 클릭 시 인덱스 감소
  const handleLeftClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? words.length - 1 : prevIndex - 1
    );
  };

  // 오른쪽 버튼 클릭 시 인덱스 증가
  const handleRightClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === words.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className='flex flex-col w-full h-full items-center'>
      <div className='flex flex-col w-full h-full'>
        {/* 헤더 */}
        <div className='flex flex-row justify-between items-center py-6'>
          <div
            onClick={() => {
              navigate('/');
            }}
            className='flex flex-row items-center gap-x-5'
          >
            <img
              src={require('../../assets/icons/common/back-btn.svg').default}
              width={44}
              height={46}
              alt='back button'
            />
            <h3 className='text-2xl text-[#990000]'>Level 1</h3>
          </div>
          <div className='flex flex-row items-center gap-x-8'>
            <div className='flex flex-row items-center gap-x-2'>
              <img
                src={
                  require('../../assets/icons/common/user-level.svg').default
                }
                width={28}
                height={28}
                alt='level icon'
              />
              {/* TODO: 상태에서 가져온 사용자의 정보 표시 */}
              <h3 className='text-[#990000] font-extrabold'>
                {userData?.displayName === ''
                  ? `${userData.schoolName} ${userData.studentGradeLevel}학년 ${userData.studentClassName}반 ${userData.studentNumber}번 `
                  : userData.displayName}
                님
              </h3>
            </div>
            <div
              className='flex flex-row items-center gap-x-2 border-[3px] border-[#FFFFFF] py-[5px] px-3 rounded-xl'
              onClick={() => {
                navigate('/login');
              }}
            >
              <img
                src={
                  require('../../assets/icons/common/logout-time.svg').default
                }
                width={28}
                height={28}
                alt='level icon'
                className='bg-cover'
              />
              <h3 className='text-[#990000] font-extrabold'>Logout</h3>
            </div>
          </div>
        </div>
        <div className='flex flex-col w-full items-center justify-center h-full'>
          <div className='flex flex-row w-full justify-between'>
            <div className='flex flex-col gap-y-4'>
              <img
                src={
                  require('../../assets/images/dialog/dialog-character.svg')
                    .default
                }
                alt='section-2'
              />
              <button>
                <img
                  src={
                    require('../../assets/images/dialog/story-btn.svg').default
                  }
                  alt='section-2'
                />
              </button>
              <button>
                <img
                  src={
                    require('../../assets/images/dialog/change-avatar-btn.svg')
                      .default
                  }
                  alt='section-2'
                />
              </button>
            </div>
            <div className='flex flex-col'>
              <div className='flex w-[728px]'>
                <img
                  src={
                    require('../../assets/images/dialog/title-bg.svg').default
                  }
                  alt='section-2'
                />
              </div>
              <div className='flex flex-col w-[724px] shadow-[0_0_12px_#0000001A] bg-white bg-opacity-60 rounded-lg h-full'>
                <div className='flex flex-row relative bg-white rounded-[8px_8px_0px_0px] justify-between items-center py-[70px] px-4'>
                  <button onClick={handleLeftClick}>
                    <img
                      src={
                        require('../../assets/images/learnLetter/arrowbtn-left.svg')
                          .default
                      }
                      alt='left-arrow'
                    />
                  </button>
                  <div className='flex flex-col items-center gap-y-3'>
                    <span className='text-4xl font-semibold'>
                      {words[currentIndex].english}
                    </span>
                    <span className='text-4xl font-semibold'>
                      {words[currentIndex].korean}
                    </span>
                  </div>
                  <button onClick={handleRightClick}>
                    <img
                      src={
                        require('../../assets/images/learnLetter/arrowbtn-right.svg')
                          .default
                      }
                      alt='right-arrow'
                    />
                  </button>
                  <div className='absolute bottom-2 left-1/2 transform -translate-x-1/2'>
                    <img
                      src={
                        require('../../assets/images/learnLetter/sound.svg')
                          .default
                      }
                      width={44}
                      alt='sound button'
                    />
                  </div>
                </div>
                <div className='flex flex-col w-full h-full items-center justify-center gap-y-4'>
                  <img
                    src={
                      require('../../assets/images/learnLetter/mic.svg').default
                    }
                    width={80}
                    alt='sound button'
                  />
                  <div className='flex flex-row gap-x-6'>
                    <img
                      src={
                        require('../../assets/images/learnLetter/translate-btn.svg')
                          .default
                      }
                      alt='translate button'
                    />
                    <img
                      src={
                        require('../../assets/images/learnLetter/hint-btn.svg')
                          .default
                      }
                      alt='hint button'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
